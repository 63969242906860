<template>
  <div class="terms-of-use">
    <div class="terms-of-use__title">Allgemeine Geschäftsbedingungen</div>
    <div class="terms-of-use__content">
      <p><b>Allgemeines</b></p>
      <p>
        <b>1.1 Geltungsbereich<br /></b>Diese Allgemeinen Geschäftsbedingungen
        gelten in der zum Zeitpunkt des Vertragsschlusses gültigen Fassung für
        alle Geschäftsbeziehungen zwischen uns der LobbySpace GmbH,
        Millerntorplatz 1, 20359 Hamburg und Ihnen. Sollten Sie entgegenstehende
        Allgemeine Geschäftsbedingungen verwenden, wird diesen hiermit
        ausdrücklich widersprochen.
      </p>
      <p>
        <b>1.2 Vertragsvereinbarung<br /></b>Vertragssprache ist Deutsch. Kunden
        im Sinne dieser Allgemeinen Geschäftsbedingungen sind ausschließlich
        Unternehmer im Sinne des § 14 BGB.
      </p>
      <p><b>1.3 Vertragsschluss</b></p>
      <p>
        <b>1.3.1 Trial und Business<br /></b>Unser Trial und Business Modell
        können Sie über unsere Website bestellen. Der Buchungsvorgang besteht
        dann aus insgesamt vier Schritten. Im ersten Schritt wählen Sie das
        gewünschte Modell aus. Im zweiten Schritt geben Sie Ihre Daten
        einschließlich Rechnungsanschrift und ggf. abweichender Anschrift ein.
        Im dritten Schritt wählen Sie die Zahlungsmethode aus. Im vierten
        Schritt haben Sie die Möglichkeit, sämtliche Angaben (z.B. Name,
        Anschrift, Zahlungsweise, gewünschtes Modell) noch einmal zu überprüfen
        und ggf. Eingabefehler zu berichtigen, bevor Sie Ihre Beauftragung durch
        Klicken auf den Button “zahlungspflichtig beauftragen” bestätigen. Mit
        der Bestellung erklären Sie verbindlich Ihr Vertragsangebot. Wir werden
        den Zugang der Beauftragung unverzüglich bestätigen. Die
        Zugangsbestätigung stellt noch keine verbindliche Annahme der
        Beauftragung dar. Wir sind berechtigt, das in der Beauftragung liegende
        Vertragsangebot innerhalb von fünf Kalendertagen nach Eingang der
        Beauftragung per E-Mail, Fax, Telefon oder postalisch verbindlich
        anzunehmen. Mit der Annahme kommt der Vertrag zustande. Der Vertragstext
        wird von uns gespeichert und Ihnen nach Absendung Ihrer Bestellung nebst
        den vorliegenden AGB und Kundeninformationen in Textform (z.B. E-Mail,
        Fax oder postalisch) zugeschickt. Der Vertragstext kann von Ihnen nach
        Absendung Ihrer Bestellung jedoch nicht mehr über die Internetseite
        abgerufen werden. Sie können über die Druckfunktion des Browsers die
        maßgebliche Website mit dem Vertragstext ausdrucken.
      </p>
      <p>
        <b>1.3.2 Enterprise und Reseller<br /></b>Der Vertragsschluss für unser
        Enterprise und Reseller Modell findet individuell durch Angebot und
        Annahme statt. Soweit nicht anders vereinbart ist hierbei der übliche
        Ablauf, dass Sie uns eine Anfrage stellen und hierauf von uns ein
        verbindliches Angebot erhalten, welches Sie dann binnen der im Angebot
        benannten Frist annehmen können. Mit der Annahme kommt der Vertrag
        zustande. Eine gesonderte Speicherung des Vertragstextes durch uns
        findet nicht statt, sondern der Vertragsinhalt ergibt sich jeweils
        individuell aus der getroffenen Vereinbarung.
      </p>
      <p>
        <b>1.4 Nachträgliche Änderung der Geschäftsbedingungen<br /></b>Wir sind
        zur nachträglichen Anpassung und Ergänzung der Allgemeinen
        Geschäftsbedingungen gegenüber bestehenden Geschäftsbeziehungen
        berechtigt, soweit Änderungen in der Gesetzgebung oder Rechtsprechung es
        erfordern oder sonstige Umstände dazu führen, dass das vertragliche
        Äquivalenzverhältnis nicht nur unwesentlich gestört ist. Eine
        nachträgliche Änderung der Geschäftsbedingungen wird wirksam, wenn Sie
        nicht innerhalb von sechs Wochen nach Mitteilung der Änderung
        widersprechen. Wir werden Sie bei Fristbeginn ausdrücklich auf die
        Wirkung Ihres Schweigens als Annahme der Vertragsänderung hinweisen und
        Ihnen während der Frist die Möglichkeit zur Abgabe einer ausdrücklichen
        Erklärung einräumen. Widersprechen Sie fristgemäß, können sowohl wir als
        auch Sie das Vertragsverhältnis außerordentlich kündigen, soweit wir
        nicht das Vertragsverhältnis unter den alten Allgemeinen
        Geschäftsbedingungen fortbestehen lassen.
      </p>
      <p><b>2. Leistungsbeschreibung</b></p>
      <p>
        <b>2.1 Allgemein<br /></b>Wir bieten Ihnen eine cloudbasierte Digital
        Signage Software zur Bewerbung Ihrer Produkte und Veranschaulichung
        Ihres Unternehmens.
      </p>
      <p>
        <b>2.2 Leistungserbringung<br /></b>Wir sind berechtigt den Vertrag bzw.
        Teile des Vertrages durch Dritte erfüllen zu lassen.
      </p>
      <p>
        <b>2.3 Leistungszeit<br /></b>Soweit nicht ausdrücklich etwas anderes
        vereinbart wurde, erbringen wir die Leistung innerhalb von 5 Tagen. Der
        Fristbeginn für die Leistung ist bei Vorkassenzahlung der Tag nach
        Erteilung des Zahlungsauftrags an das überweisende Kreditinstitut bzw.
        bei Zahlung auf Rechnung der Tag nach Vertragsschluss. Die Frist endet
        am darauffolgenden fünften Tag. Fällt der letzte Tag der Frist auf einen
        Samstag, Sonntag oder einen am Leistungsort staatlich anerkannten
        allgemeinen Feiertag, endet die Frist am nächsten Werktag.
      </p>
      <p>
        <b>2.4 Google Firebase<br /></b
        ><span
          style="font-family: var( --e-global-typography-text-font-family ), Sans-serif; letter-spacing: -0.2px; display: inline !important;"
          >Unsere Software liegt auf einem Server des Googles Dienstes Firbease
          (betrieben von der Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland). Bei Google Firebase handelt es sich um eine
          Plattform für Entwickler von Apps für mobile Geräte und Websites.
          Sofern Sie unseren Dienst nutzen, werden Ihre Daten daher
          notwendigerweise an Google gesendet und dort gespeichert. Die mittels
          von Google Firebase verarbeiteten Informationen werden ggf. zusammen
          mit weiteren Diensten von Google, wie z.B. Google Analytics und den
          Google-Marketing-Diensten verwendet. In diesem Fall werden nur
          pseudonyme Informationen verarbeitet, um Geräte der Nutzer zu
          identifizieren. Rechtsgrundlage für die Nutzung ist Art. 6 I S. 1 f
          DSGVO.</span
        >
      </p>
      <p>
        Die Datenschutzerklärung von Google ist unter
        https://www.google.com/policies/privacy abrufbar.
      </p>
      <p><b>Zahlung</b></p>
      <p>
        <b>3.1 Preise<br /></b>Sämtliche Preise verstehen sich zuzüglich
        Umsatzsteuer.
      </p>
      <p>
        <b>3.2 Zahlungsverzug<br /></b>Sie geraten mit der Zahlung in Verzug,
        wenn die Zahlung nicht innerhalb von zwei Wochen nach Erhalt der
        Rechnung bei uns eingeht. Bei Zahlungsverzug werden Zinsen in Höhe von 9
        Prozentpunkten über dem Basiszinssatz der Europäischen Zentralbank
        berechnet. Sollten Sie mit Ihren Zahlungen in Verzug geraten, so
        behalten wir uns vor, Mahngebühren in Höhe von 2,50 Euro in Rechnung zu
        stellen. Die Geltendmachung eines darüber hinaus gehenden
        Schadensersatzes bleibt unbenommen. Ihnen verbleibt die Möglichkeit
        nachzuweisen, dass uns kein oder ein geringerer Schaden entstanden ist.
      </p>
      <p>
        <b>3.3 Zurückbehaltungsrecht<br /></b>Die Geltendmachung eines
        Zurückbehaltungsrechts steht Ihnen nur für solche Gegenansprüche zu, die
        fällig sind und auf demselben rechtlichen Verhältnis wie Ihre
        Verpflichtung beruhen.
      </p>
      <p><b>Ihre Verantwortlichkeit</b></p>
      <p>
        <b>4.1 Allgemeines<br /></b>Sie sind allein für Inhalt und Richtigkeit
        der von Ihnen übermittelten und selbst eingepflegten Daten, Beiträge und
        Informationen verantwortlich. Diese dürfen mit den Inhalten weder gegen
        geltende Gesetze, noch diese Allgemeine Geschäftsbedingungen verstoßen.
        Sie verpflichten sich zudem, keine Daten zu übermitteln, deren Inhalte
        Rechte Dritter (z.B. Persönlichkeitsrechte, Namensrechte, Markenrechte,
        Urheberrechte etc.) verletzen. Insbesondere dürfen Beiträge mit
        strafbaren Inhalten nicht veröffentlicht oder unwahre Tatsachen
        behauptet werden.
      </p>
      <p>
        <b>4.2 Freistellung<br /></b>Sie halten uns von allen Ansprüchen frei,
        die von Dritten wegen solcher Verletzungen gegenüber uns geltend gemacht
        werden. Dies umfasst auch die Erstattung von Kosten notwendiger
        rechtlicher Vertretung.
      </p>
      <p>
        <b>4.3 Datensicherung<br /></b>Für die Sicherung der übersandten
        Informationen sind Sie mitverantwortlich. Wir können nicht für den
        Verlust von Ihren übersandten Informationen verantwortlich gemacht
        werden, da wir keine allgemeine Datensicherungsgarantie übernehmen.
      </p>
      <p>
        <b>4.4 Missbräuchliche Beiträge<br /></b>Missbräuchliche Inhalte bzw.
        Beiträge werden ohne Vorankündigung von uns deaktiviert oder gelöscht.
        Solche Inhaltsgestaltungen sind z.B. in folgenden Fällen gegeben:
      </p>
      <p>
        – zum Versenden von Spam,<br />– zum Senden und Speichern verletzender,
        obszöner, bedrohlicher, beleidigender oder in sonstiger Weise Rechte
        Dritter verletzender Inhalte,<br />– zum Senden und Speichern von Viren,
        Würmern, Trojanern sowie schädlicher Computer Codes, Files, Scripts,
        Agents oder Programme,<br />– zum Hochladen von Programmen die geeignet
        sind den Betrieb zu stören, zu beeinträchtigen oder zu verhindern,<br />–
        mit dem Versuch, unautorisierten Zugang zu unserem Service oder zu
        einzelnen Modulen, Systemen oder Anwendungen zu erhalten oder diesen
        Dritten zu gewähren,<br />– Inhalte mit gewaltverherrlichendem,
        pornografischem oder anderweitig anstößigem oder strafbarem Inhalt.
      </p>
      <p>
        Bei wiederkehrenden Verstößen behalten wir uns vor, Ihren Account zu
        sperren bzw. zu löschen. Bereits getätigte Zahlungen können in diesem
        Fall nicht zurückerstattet werden. Das Recht zu außerordentlichen
        Kündigung bleibt hiervon unberührt.
      </p>
      <p>
        <b>4.5 Vertraulichkeit<br /></b>Ihre Rechte aus diesem Vertrag sind
        nicht übertragbar. Das Passwort, welches Ihnen den Zugang zu Ihrem
        persönlichen Bereich und somit auch die Datenerfassung ermöglicht, ist
        streng vertraulich zu behandeln und darf an Dritte keinesfalls
        weitergegeben werden. Sie treffen die geeigneten und angemessenen
        Maßnahmen, um eine Kenntnisnahme Ihres Passwortes durch Dritte zu
        verhindern. Ein Kundenaccount kann nicht auf andere Nutzer/Kunden oder
        sonstige Dritte übertragen werden. Für Schäden die durch den Missbrauch
        des Passwortes entstehen haften wir nicht.
      </p>
      <p>
        <b>4.6 Profildaten<br /></b>Sie sind verpflichtet die von Ihnen
        eingestellten Inhalte und Profilangaben immer auf dem aktuellen Stand zu
        halten und uns sofort über einen Missbrauch Ihres Profils zu
        informieren.
      </p>
      <p>
        <b>4.7 Branchenspezifische Kenntnisse<br /></b>Auch möchten wir Sie
        darauf hinweisen, dass wir keine branchenspezifischen Kenntnisse über
        Ihr Projekt bzw. Ihr Unternehmen haben. Wir stellen Ihnen lediglich
        unsere Software zur Verfügung. Die konkrete Handhabe obliegt Ihnen. Ein
        bestimmter werblicher Erfolg ist nicht geschuldet.
      </p>
      <p><b>4.8 Hinweise</b></p>
      <p>
        Sie haben die ggf. zur Verfügung gestellten bzw. durch uns mitgeteilten
        Anleitungen/Hinweise zur Nutzung der Software stets zu beachten. Wir
        können daher nicht für Ihr fehlerhaftes Verhalten verantwortlich gemacht
        werden.
      </p>
      <p><b>4.9 Leistungsumfang</b></p>
      <p>
        Bitte beachten Sie stets den konkret vereinbarten Leistungsumfang.
        Sollten Sie die Software über das vereinbarte Maß hinaus nutzen, können
        wir das Vertragsverhältnis mit Ihnen aus wichtigem Grund kündigen.
        Bereits getätigte Zahlungen können in diesem Fall nicht zurückerstattet
        werden.
      </p>
      <p><b>4.10 Mini-PC</b></p>
      <p>
        Am Ort des Mini-PCs muss eine Downloadgeschwindigkeit von mind. 20
        Mbit/s je Mini-PC vorhanden sein. Zudem haben wir uneingeschränkten
        Zugriff auf den Mini-PC, um Wartungen und Updates durchzuführen. Es
        werden dabei Daten wie Online-Zustand, WLAN-Stärke, CPU-Auslastung,
        Wärmeentwicklung, LAN und WAN IP und das aktuelle Ausgabebild an uns
        übersandt. Wir können auch Screenshots des jeweiligen Mini-PCs zu
        Wartungszwecken erstellen. Für eine reibungslose Bedienung der Software
        empfehlen wir den kostenlosen Chrome Browser.
      </p>
      <p>
        <b>Referenzrecht<br /></b>Wir räumen uns das Recht ein, uns auf den von
        uns angebotenen Dienstleistungen und Werken zu referenzieren bzw. Sie
        als Referenz zu nennen. Hiergegen steht Ihnen ein Widerspruchsrecht zu.
      </p>
      <p><b>Urheberrecht und Nutzungsrechte</b></p>
      <p>
        <b>6.1 Nutzungsrechte und Nutzungsumfang<br /></b>Wir haben an der zur
        Verfügung gestellten Software das Urheberrecht bzw. dass ausschließliche
        Nutzungsrecht. Das Urheberrecht umfasst insbesondere den vollständigen
        Programmcode, den Aufbau sowie das Erscheinungsbild und das Design der
        Software. Mit Vertragsschluss und der Zahlung des vereinbarten
        Jahresbeitrags, sichern Sie sich lediglich die Nutzungsrechte an der
        Software zum vereinbarten Zweck. Sie sind ohne ausdrückliche Zustimmung
        von uns nicht berechtigt, die von uns erteilten Nutzungsrechte
        entgeltlich oder unentgeltlich auf Dritte zu übertragen oder anderweitig
        an solche weiterzugeben. Die Übertragung weiterer Nutzungsrechte kann
        separat mit uns vereinbart werden und ist gesondert zu vergüten. Jede
        verändernde Vervielfältigung, Weitergabe, Änderung usw. der Software ist
        ohne ausdrückliche Genehmigung nicht gestattet.
      </p>
      <p>
        <b>6.2 Urheberbenennung<br /></b>Soweit wir einen
        Copyright-Hinweis/Urhebervermerk an der Software angebracht haben,
        dürfen Sie diesen ohne unsere Zustimmung nicht entfernen oder verändern.
      </p>
      <p><b>6.3 Updates und Upgrades</b></p>
      <p>
        Wir sind berechtigt, die Software im Rahmen von (notwendigen) Updates
        und Upgrades zu verändern. Diese Veränderungen unterliegen ebenfalls dem
        urheberrechtlichen Schutz.
      </p>
      <p>
        <b>6.4 Schadensersatz<br /></b>Wir behalten uns das Recht vor, für jeden
        Verstoß gegen die vertraglichen Lizenzbedingungen, insbesondere bei
        Verletzung des Urheberrechts, den entstandenen Schaden geltend zu
        machen.
      </p>
      <p>
        <b>Laufzeit und Kündigung <br /></b>Unsere Vertragsmodelle können
        jeweils mit einer Kündigungsfrist von drei Monaten zum jeweiligen
        Laufzeitende in Textform, ohne Angaben von Gründen gekündigt werden.
        Unterbleibt eine rechtzeitige Kündigung, verlängert sich der Vertrag
        automatisch um die jeweilige Erstlaufzeit jedoch maximal um ein Jahr.
        Das Recht zur außerordentlichen Kündigung aus wichtigem Grund wird
        hierdurch nicht berührt.
      </p>
      <p><b>Inhaltsnutzung</b></p>
      <p>
        Mit dem Einstellen von Inhalten in die Datenbank räumen Sie uns das
        Recht ein, diese Inhalte unbefristet für die Einstellung und
        Bereithaltung in die Datenbank und den Abruf und Speicherung durch
        Dritte zu nutzen, insbesondere die Inhalte zu speichern, zu
        vervielfältigen, bereitzuhalten, zu übermitteln, zu verlinken und zu
        veröffentlichen. Dies kann auch durch oder in Form von Werbemitteln
        (insbesondere die Verlinkung auf sozialen Netzwerken, die Verwendung für
        Spots, die Verwendung für die eigene Website oder auch in gedruckter
        Form etc.) von uns oder durch Dritte geschehen. Hierbei verzichtet der
        Urheber explizit auf seine Namensnennung. Des Weiteren erhalten wir das
        Recht, die Inhalte umzuarbeiten, insbesondere wenn diese nicht den oben
        genannten Anforderungen dieser Bedingungen entsprechen.
      </p>
      <p><b>9. Nutzbarkeit der Dienste</b></p>
      <p>
        <b>9.1 Weiterentwicklung der Dienstleistung / Verfügbarkeit<br /></b>Wir
        sind bemüht, unsere Leistungen an aktuelle technische Entwicklungen
        anzupassen. Wir behalten uns daher Änderungen der vereinbarten
        Leistungen vor, soweit solche Änderungen nicht die Kernleistungen
        beeinträchtigen und unter Berücksichtigung der Interessen des
        Vertragspartners für diesen zumutbar sind. Auch sind wir berechtigt den
        Website-Betrieb zu Zwecken der Aktualisierung und Wartung teilweise oder
        komplett im zumutbaren Rahmen zu unterbrechen. Wir übernehmen insoweit
        keine Garantie für die jederzeitige Verfügbarkeit der angebotenen
        Leistungen und sichern nicht zu, dass die angebotenen Leistungen oder
        Teile davon von jedem Ort aus verfügbar gemacht werden und genutzt
        werden können. Das gesetzliche Gewährleistungsrecht wird hierdurch nicht
        berührt.
      </p>
      <p>
        <b>9.2 Technische Voraussetzungen<br /></b>Die Nutzung der Software
        setzt entsprechende kompatible Geräte voraus. Es obliegt Ihnen Ihre
        Geräte in einen Zustand zu versetzen oder zu halten, welche die Nutzung
        der Website-Dienste ermöglicht.
      </p>
      <p>
        <b>9.3 Erreichbarkeit und Unterbrechungen</b>
      </p>
      <p>
        Wir garantieren eine Erreichbarkeit unserer Dienste von 95,00 % im
        Jahresmittel. Hiervon ausgenommen sind Ausfallzeiten durch Wartung und
        Software-Updates sowie Zeiten, in denen die Server aufgrund von
        technischen Problemen, die nicht in unserem Einflussbereich liegen,
        nicht über das Internet erreichbar sind. Bei einer Unterschreitung der
        95,00 % im Jahresmittel sind Sie berechtigt, das Entgelte ab dem
        Zeitpunkt des Eintritts bis zum Wegfall der Behinderung entsprechend zu
        mindern. Wir übernehmen keine Haftung für Einschränkungen und
        Beeinträchtigungen der Erreichbarkeit der Daten auf einem Server, die
        außerhalb unseres Einflussbereichs liegen und die wir nicht zu vertreten
        haben.Wir werden die Unterbrechungen so gering wie möglich halten und,
        soweit möglich, über die Unterbrechungen informieren. Für den Verlust
        von Daten und/oder Programmen haften wir insoweit nicht, als der Schaden
        darauf beruht, dass Sie es unterlassen haben, Datensicherungen
        durchzuführen und dadurch sicherzustellen, dass verloren gegangene Daten
        mit vertretbarem Aufwand wiederhergestellt werden können und wir diesen
        Verlust bzw. eine Beschädigung nicht zu vertreten haben.
      </p>
      <p><b>9.4 Störungen</b></p>
      <p>
        Bei Mängeln oder Störungen bitten wir Sie, dies unverzüglich anzuzeigen,
        damit wir das Problem beseitigen können. Sollte die Störung auf Ihr
        Verschulden zurückzuführen sein und die Beseitigung/Bearbeitung nicht zu
        Ihrem gebuchten Leistungsumfang gehören, werden wir Ihnen die uns
        entstandenen Kosten für die Überprüfung und ggf. erforderliche
        Beseitigung in Rechnung stellen.
      </p>
      <p><b>Haftung</b></p>
      <p><b>10.1 Auskünfte</b></p>
      <p>
        Auskünfte über Anwendungsmöglichkeiten der von uns zur Verfügung
        gestellten Software sowie die technische Beratung und sonstige Angaben
        erfolgen nach bestem Wissen und Gewissen. Es gilt auch insoweit der
        Haftungsausschluss und -vorbehalt aus den nachfolgenden Regelungen. Sie
        haben jedoch stets selbst dafür Sorge zu tragen, dass Sie sich vor einer
        Bestellung informieren und kontrollieren, ob die Software für Ihr
        geplantes Vorhaben geeignet ist.
      </p>
      <p>
        <b>10.2 Haftungsausschluss<br /></b>Wir sowie unsere gesetzlichen
        Vertreter und Erfüllungsgehilfen haften nur für Vorsatz. Nur wenn
        wesentliche Vertragspflichten (folglich solche Pflichten, deren
        Einhaltung für die Erreichung des Vertragszwecks von besonderer
        Bedeutung ist) betroffen sind, wird auch für grobe oder leichte
        Fahrlässigkeit gehaftet. Dabei beschränkt sich die Haftung auf den
        vorhersehbaren, vertragstypischen Schaden.
      </p>
      <p>
        <b>10.3 Haftungsvorbehalt<br /></b>Der vorstehende Haftungsausschluss
        betrifft nicht die Haftung für Schäden aus der Verletzung des Lebens,
        des Körpers oder der Gesundheit. Auch die Vorschriften des
        Produkthaftungsgesetzes bleiben von diesem Haftungsausschluss unberührt.
      </p>
      <p>
        <b>10.4 Datensicherung<br /></b>Wir führen im Rahmen der
        Leistungserbringung effektive Datensicherungen durch, übernehmen jedoch
        keine allgemeine Datensicherungsgarantie für die von Ihnen übermittelten
        Daten. Sie sind auch selbst dafür verantwortlich in regelmäßigen
        Abständen angemessene Backups Ihrer Daten zu erstellen und so einem
        Datenverlust vorzubeugen. Wir lassen bei der Erbringung der vereinbarten
        Dienstleistung die angemessene Sorgfalt walten und werden die
        Datensicherung mit der erforderlichen Fachkenntnis erbringen. Wir
        sichern jedoch nicht zu, dass die gespeicherten Inhalte oder Daten, auf
        die Sie zugreifen, nicht versehentlich beschädigt oder verfälscht
        werden, verloren gehen oder teilweise entfernt werden.
      </p>
      <p>
        <b>10.5 Haftung für Inhalte<br /></b>Wir haften als Betreiber der
        Software nicht für inkorrekte Angaben der Kunden, die diese in unsere
        Software einpflegen. Eine Überprüfung der in unserer Software
        eingestellten Inhalte (insbesondere bzgl. der Verletzung von Rechten
        Dritter) findet nicht statt. Sollten wir jedoch von fehlerhaften,
        unzutreffenden, irreführenden oder rechtswidrigen Angaben Kenntnis
        erlangen, werden wir diese umgehend überprüfen und ggf. entfernen. Wir
        übernehmen zudem keine Gewähr für die Richtigkeit, Aktualität,
        Vollständigkeit, Qualität oder Rechtmäßigkeit der nicht von uns
        stammenden Inhalte. Wir stellen unseren Kunden lediglich unsere Software
        im technischen Sinne zur Verfügung.
      </p>
      <p><b>11. Schlussbestimmungen</b></p>
      <p>
        <b>11.1 Gerichtsstand<br /></b>Als ausschließlicher Gerichtsstand für
        alle Rechtsstreitigkeiten aus diesem Vertrag wird unser Geschäftssitz
        vereinbart, sofern Sie Kaufmann, eine juristische Person des
        öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen sind.
      </p>
      <p>
        <b>11.2 Rechtswahl<br /></b>Soweit nicht zwingende gesetzliche
        Bestimmungen nach Ihrem Heimatrecht entgegenstehen, gilt deutsches Recht
        unter Ausschluss des UN-Kaufrechts als vereinbart.
      </p>
      <p>
        <b>11.3 Salvatorische Klausel<br /></b>Die Unwirksamkeit einzelner
        Bestimmungen berührt die Gültigkeit der übrigen Allgemeinen
        Geschäftsbedingungen nicht.
      </p>
      <p>Stand Juni 2019</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.terms-of-use {
  padding: 32px;
  max-width: 1000px;
  margin: 0 auto;

  &__title {
    @apply mb-4 text-4xl font-semibold text-center;
  }

  &__content {
  }

  &__left,
  &__right {
    width: 50%;
    padding: 24px;
  }

  p {
    @apply mb-4;
  }
}
</style>
